<template>
  <div>
    <div style="margin-top:5px" />
    <v-layout
      align-center
      justify-center
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs6
        sm6
        md6
        lg6
        xl6
      >
        <v-divider color="black" class="mt-3 mb-3" />
      </v-flex>
      <v-flex
        xs1
        sm1
        md1
        lg1
        xl1
      >
        <v-img
          src="/assets/edliyProton.svg"
          aspect-ratio="1"
          contain
          class="mx-auto"
          max-width="35px"
        />
      </v-flex>
      <v-flex
        xs6
        sm6
        md6
        lg6
        xl6
      >
        <v-divider color="black" class="mt-3 mb-3" />
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
    name: 'DivideContent',
/*    props: {
        myStyle: {
            type: Object,
            default: () => {}
        }},
    data () { return {}}, */
}
</script>
