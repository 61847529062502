<template>
  <div>
    <v-layout
      align-center
      justify-center
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm12
        md12
        lg12
        xl12
      >
        <div
          class="font-weight-bold"
          :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
        >
          <v-layout class="mt-4">
            <h1 style="text-align:start">
              <!--  <span class="header-etsy">&epsilon;</span>dliy &mdash; -->
              What is <span class="header-etsy">&epsilon;</span>dliy?
            </h1>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>
    <div style="margin-top:5px" />
    <v-layout
      align-center
      justify-start
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm12
        md12
        lg12
        xl12
        class="font-weight-light"
        :class="{'headline px-4': $vuetify.breakpoint.lgAndUp,'subtitle-1 px-2': $vuetify.breakpoint.mdOnly, 'body-2 px-0': $vuetify.breakpoint.smAndDown}"
      >
        <span class="edliy-e">&epsilon;</span>dliy offers educators and students a wide array of learning aids and activities that assist them in every step of their learning journey.
        <div
          class="font-weight-bold"
          :class="{'headline': $vuetify.breakpoint.mdAndUp, 'body-2': $vuetify.breakpoint.smAndDown}"
        >
          <br>
          <a href="/subjects" class="btnLeft"> See Examples</a>
        </div>
      </v-flex>
    </v-layout>
    <br>
    <v-layout
      justify-center
      align-center
      class="mb-3 pa-1"
    >
      <v-flex
        xl4
        sm4
        md4
        lg4
      >
        <v-layout justify-center align-center>
          <v-img
            src="/assets/eggOne.svg"
            max-height="40px"
            max-width="40px"
            contain
          />
        </v-layout>
        <v-layout class="mt-1" justify-center align-center>
          <h5 style="color:black"> Build Concepts </h5>
        </v-layout>
      </v-flex>
      <v-flex
        xl4
        sm4
        md4
        lg4
      >
        <v-layout justify-center align-center>
          <v-img
            src="/assets/eggTwo.svg"
            max-height="40px"
            max-width="40px"
            contain
          />
        </v-layout>
        <v-layout class="mt-1" justify-center align-center>
          <h5 style="color:black"> Practice Problems </h5>
        </v-layout>
      </v-flex>
      <v-flex
        xl4
        sm4
        md4
        lg4
      >
        <v-layout justify-center align-center>
          <v-img
            src="/assets/eggThree.svg"
            max-height="40px"
            max-width="40px"
            contain
          />
        </v-layout>
        <v-layout class="mt-1" justify-center align-center>
          <h5 style="color:black"> Test Knowledge </h5>
        </v-layout>
      </v-flex>
    </v-layout>
    <br>
    <v-layout
      justify-center
      align-center
      row
      wrap
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <div class="font-weight-bold"
             :class="{'display-1 px-4': $vuetify.breakpoint.lgAndUp,'headline px-2': $vuetify.breakpoint.mdOnly, 'title px-0': $vuetify.breakpoint.smAndDown}"
        >
          <span style="font-family:Roboto"> Build & Master Concepts</span>
        </div>
        <div style="margin-top:5px" />
        <div
          class="font-weight-light"
          :class="{'headline px-4': $vuetify.breakpoint.lgAndUp, 'subtitle-1 px-2': $vuetify.breakpoint.mdOnly,'body-2 px-0': $vuetify.breakpoint.smAndDown}"
        >
          <span class="edliy-e">&epsilon;</span>dliy offers a hands-on approach to learning science and math. Not only hands-on learning is a lot more fun, it is also proven to improve engagement and retention.
          <div style="margin-top:5px" />
          <a class="btnLeft mt-2 mb-4" href="/chemistry/bce"> Learn More</a>
        </div>
      </v-flex>
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <carousel
          class="mx-auto"
          :scroll-per-page="true"
          pagination-position="bottom"
          pagination-color="#FFD600"
          pagination-active-color="#FFC200"
          :pagination-size="15"
          :per-page="1"
          :pagination-padding="6"
          :mouse-drag="false"
          :touch-drag="false"
          :overlay="4"
        >
          <slide>
            <!--<h5 style="text-align:center">
              Swipe left to try it yourself.
            </h5>-->
            <v-layout justify-center>
              <video autoplay
                     loop
                     muted
                     playsinline
                     class="gif"
              >
                 <!--<source src="assets/webm-1.webm" type="video/webm">-->
               <source src="assets/webm-1.mp4" type="video/mp4">
              </video>
            </v-layout>
          </slide>
          <slide>
            <!--<h5 style="text-align:center">
              Swipe right to watch a video blurb.
            </h5>-->
            <v-layout justify-center>
              <div id="jxgbox1a" class="edliy-box-abt" style="jsxgraph" />
            </v-layout>
          </slide>
        </carousel>
        <v-divider class="mt-3 mb-3" />
      </v-flex>
    </v-layout>
    <div style="margin-top: 20px" />
    <!-- ========== Slide 2 ========== -->
    <v-layout
      justify-center
      align-center
      row
      wrap
      reverse="$vuetify.breakpoint.mdAndUp"
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-1': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <div
          class="font-weight-bold"
          :class="{'display-1': $vuetify.breakpoint.mdAndUp,'headline': $vuetify.breakpoint.mdOnly, 'title': $vuetify.breakpoint.smAndDown}"
        >
        <span style="font-family:Roboto">Practice Problem Solving</span>
        </div>
        <div style="margin-top: 5px" />
        <div
          class="font-weight-light"
          :class="{'headline': $vuetify.breakpoint.lgAndUp, 'subtitle-1': $vuetify.breakpoint.mdOnly, 'body-2': $vuetify.breakpoint.smAndDown}"
        >
          <span class="edliy-e">&epsilon;</span>dliy's visually inteactive activities let you get as much practice as you need to master a concept.
          Plus, you get real-time feedback &mdash; creating opportunities for you to learn and improve.
          <div style="margin-top:5px" />
          <a class="btnLeft mt-2 mb-4" href="/chemistry/bce">Try it Yourself</a>
        </div>
      </v-flex>
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <carousel
          class="mx-auto"
          :scroll-per-page="true"
          pagination-position="bottom"
          pagination-color="#FFD600"
          pagination-active-color="#FFC200"
          :pagination-size="15"
          :per-page="1"
          :pagination-padding="6"
          :mouse-drag="false"
          :touch-drag="false"
        >
          <slide>
            <!--<h5 style="text-align:center">
              Swipe left to try it yourself.
            </h5>-->
            <v-layout justify-center>
              <video autoplay
                     loop
                     muted
                     playsinline
                     class="gif"
              >
               <!-- <source src="assets/webm-2.webm" type="video/webm">-->
             <source src="assets/webm-2.mp4" type="video/mp4">
              </video>
            </v-layout>
          </slide>
          <slide>
            <!--<h5 style="text-align:center">
              Swipe right to watch a video blurb.
            </h5>-->
            <v-layout justify-center>
              <div id="jxgbox2a" class="edliy-box-abt" />
            </v-layout>
          </slide>
        </carousel>
        <v-divider class="mt-3 mb-3" />
      </v-flex>
    </v-layout>
    <div style="margin-top: 20px" />
    <v-layout
      align-center
      justify-center
      row
      wrap
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <div
          class="font-weight-bold"
          :class="{'display-1 px-4': $vuetify.breakpoint.lgAndUp,'headline px-2': $vuetify.breakpoint.mdOnly, 'title px-0': $vuetify.breakpoint.smAndDown}"
        >
          <span style="font-family:Roboto">
            Test Your Knowledge
          </span>
        </div>
        <div style="margin-top: 5px" />
        <div
          class="font-weight-light text-h1"
          :class="{'headline px-4': $vuetify.breakpoint.lgAndUp,'subtitle-1 px-2': $vuetify.breakpoint.mdOnly, 'body-2 px-0': $vuetify.breakpoint.smAndDown}"
        >
          Every student is different and learns at a different pace. That's why
          all modules in <span class="edliy-e">&epsilon;</span>dliy are designed to be self-paced &mdash; So, students can learn at a pace that works best for them.
          <div style="margin-top:5px" />
          <a class="btnLeft mt-2 mb-4" href="/mathematics/coord">See Examples</a>
        </div>
      </v-flex>
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <carousel
          class="mx-auto"
          :scroll-per-page="true"
          pagination-position="bottom"
          pagination-color="#FFD600"
          pagination-active-color="#FFC200"
          :pagination-size="15"
          :per-page="1"
          :pagination-padding="6"
          :mouse-drag="false"
          :touch-drag="false"
        >
          <slide>
           <!-- <h5 style="text-align:center">
              Swipe left to try it yourself.
            </h5> -->
            <v-layout justify-center>
              <video autoplay
                     loop
                     muted
                     playsinline
                     class="gif"
              >
                <!-- <source src="assets/webm-3.webm" type="video/webm">-->
               <source src="assets/webm-3.mp4" type="video/mp4"> 
              </video>
            </v-layout>
          </slide>
          <slide>
         <!--  <h5 style="text-align:center">
              Swipe right to watch a video blurb.
            </h5>--> 
            <v-layout justify-center>
              <div id="jxgbox6a" class="edliy-box-abt" />
            </v-layout>
          </slide>
        </carousel>
        <v-divider class="mt-3 mb-3" />
      </v-flex>
    </v-layout>
    <div style="margin-top: 10px" />
    <v-layout
      align-center
      justify-center
      row
      wrap
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
      reverse="$vuetify.breakpoint.mdAndUp"
    >
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <div
          class="font-weight-bold"
          :class="{'display-1': $vuetify.breakpoint.mdAndUp,'headline': $vuetify.breakpoint.mdOnly, 'title': $vuetify.breakpoint.smAndDown}"
        >
          <span style="font-family:Roboto"> Learn the Way You Want </span>
        </div>
        <div style="margin-top: 10px" />
        <div
          class="font-weight-light subheader-content"
          :class="{'headline': $vuetify.breakpoint.lgAndUp, 'subtitle-1': $vuetify.breakpoint.mdOnly, 'body-2': $vuetify.breakpoint.smAndDown}"
        >
          <span class="edliy-e">&epsilon;</span>dliy supports all types of learners and learning practices. No matter whether you want to attend the class in person or learn online &mdash; we are there for you.
          <br>
          <a v-scroll-to="'#daf'" class="btnLeft mt-2 mb-4">Get Started</a>
        </div>
      </v-flex>
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <carousel
          class="mx-auto"
          :scroll-per-page="true"
          pagination-position="bottom"
          pagination-color="#FFD600"
          pagination-active-color="#FFC200"
          :pagination-size="15"
          :per-page="1"
          :pagination-padding="6"
          :mouse-drag="false"
          :touch-drag="false"
        >
          <slide>
           <!-- <h5 style="text-align:center">
              Swipe left to try it yourself.
            </h5>-->
            <v-layout justify-center>
              <video autoplay
                     loop
                     muted
                     playsinline
                     class="gif"
              >
              <!--  <source src="assets/webm-4.webm" type="video/webm"> -->
               <source src="assets/webm-4.mp4" type="video/mp4"> 
              </video>
            </v-layout>
          </slide>
          <slide>
            <!--<h5 style="text-align:center">
              Swipe right to watch a video blurb.
            </h5>-->
            <v-layout justify-center>
              <div id="jxgbox5a" class="edliy-box-abt" />
            </v-layout>
          </slide>
        </carousel>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
import DivideContent from './Divider.vue'
export default {
    name: 'EdliyContent',
    components: {
      DivideContent
    },
    data () {
      return {
        style: {layout: "margin: 10px"},
            }
      },
    mounted() {
      Boxes.box1a();
      Boxes.box2a();
      Boxes.box6a();
      Boxes.box5a();
    },
    methods : {
      reloadPage()
      {
      window.location.reload()
      }
    }
}
</script>
