<template>
  <div id="sc123">
    <v-layout
      align-center
      justify-center
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm12
        md12
        lg12
        xl12
      >
        <div
          :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
        >
          <v-layout class="mt-4">
            <h1 style="text-align:start">
              Why <span class="header-etsy">&epsilon;</span>dliy?
            </h1>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>
    <div style="margin-top: 10px" />
    <v-layout
      align-center
      justify-center
      row
      wrap
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm12
        md12
        lg12
        xl12
        class="font-weight-light subheader-content"
        :class="{'headline px-4': $vuetify.breakpoint.lgAndUp,'subtitle-1 px-2': $vuetify.breakpoint.mdOnly, 'body-2 px-0': $vuetify.breakpoint.smAndDown}"
      >
        Through visual interactivity,  <span class="edliy-e">&epsilon;</span>dliy empowers students to learn from virtual experiments, test different hypotheses, and gain a 360<sup>o</sup> view of the concepts in Science, Engineering, Technology and Mathematics (STEM).
        <div
          class="font-weight-light"
          :class="{'headline': $vuetify.breakpoint.mdAndUp, 'body-2': $vuetify.breakpoint.smAndDown}"
        >
          <br>
          <a href="/demo" class="btnLeft"> Watch Demo Videos</a>
        </div>
      </v-flex>
    </v-layout>
    <div style="margin-top: 20px" />
    <v-layout
      align-center
      justify-center
      row
      wrap
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <div
          class="font-weight-bold"
          :class="{'display-1 px-4': $vuetify.breakpoint.lgAndUp,'headline px-2': $vuetify.breakpoint.mdOnly, 'title px-0': $vuetify.breakpoint.smAndDown}"
        >
          <span style="font-family:Roboto">Learning by Doing</span>
        </div>
        <div style="margin-top: 10px" />
        <div
          class="font-weight-light subheader-content"
          :class="{'headline px-4': $vuetify.breakpoint.lgAndUp, 'subtitle-1 px-2': $vuetify.breakpoint.mdOnly, 'body-2 px-0': $vuetify.breakpoint.smAndDown}"
        >
          <span class="edliy-e">&epsilon;</span>dliy enables students to learn through virtual
          experiments and interactive activities &mdash; empowering them to intuitively build and shape their understanding.
          <br>
          <a v-scroll-to="'#subjectcard123'" class="btnLeft mt-3">Start Learning</a>
        </div>
      </v-flex>
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
        justify-center
        align-center
      >
        <v-img
          width="auto "
          class="mx-auto"
          max-height="500px"
          max-width="500px"
          src="assets/animation-1.svg"
          contain
        />
        <v-divider class="mt-3 mb-3" />
      </v-flex>
    </v-layout>
    <div style="margin-top: 20px" />
    <v-layout
      align-center
      row
      wrap
      reverse="$vuetify.breakpoint.mdAndUp"
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <div
          class="font-weight-bold"
          :class="{'display-1 px-4': $vuetify.breakpoint.lgAndUp,'headline px-2': $vuetify.breakpoint.mdOnly, 'title px-0': $vuetify.breakpoint.smAndDown}"
        >
          <span style="font-family:Roboto">Learning without Borders</span>
        </div>
        <div style="margin-top: 10px" />
        <div
          class="font-weight-light subheader-content"
          :class="{'headline px-4': $vuetify.breakpoint.lgAndUp,'subtitle-1 px-2': $vuetify.breakpoint.mdOnly, 'body-2 px-0': $vuetify.breakpoint.smAndDown}"
        >
          <span class="edliy-e">&epsilon;</span>dliy is built on cloud-era technology. Our interactive modules and apps are designed
          to run on the cloud &mdash; accessible from anywhere, at anytime, and on any device.
          <br>
          <a v-scroll-to="'#subjectcard123'" class="btnLeft mt-3">Try It Yourself</a>
        </div>
      </v-flex>
      <v-flex xs12
              sm6
              md6
              lg6
              xl6
      >
        <v-img
          width="auto "
          class="mx-auto"
          max-height="500px"
          max-width="500px"
          src="assets/animation-2.svg"
          contain
        />
        <v-divider class="mt-3 mb-3" />
      </v-flex>
    </v-layout>
    <div style="margin-top: 20px" />
    <v-layout
      align-center
      row
      wrap
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <div class="font-weight-bold" :class="{'display-1 px-4': $vuetify.breakpoint.lgAndUp,'headline px-2': $vuetify.breakpoint.mdOnly, 'title px-0': $vuetify.breakpoint.smAndDown}">
          <span style="font-family:Roboto">Learning from the Bests</span>
        </div>
        <div style="margin-top: 10px" />
        <div class="font-weight-light subheader-content"
             :class="{'headline px-4': $vuetify.breakpoint.lgAndUp,'subtitle-1 px-2': $vuetify.breakpoint.mdOnly, 'body-2 px-0': $vuetify.breakpoint.smAndDown}"
        >
          Our creators include educators, scientists and technologists trained at
          some of the world's most prestigious schools and institutions &mdash; MIT, Harvard, Stanford, and Caltech, to name a few.
          <br>
          <a v-scroll-to="'#subjectcard123'" class="btnLeft mt-3"> Explore Edliy</a>
        </div>
      </v-flex>
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <v-img
          width="auto "
          class="mx-auto"
          max-height="500px"
          max-width="500px"
          src="assets/animation-3.svg"
          contain
        />
        <v-divider class="mt-3 mb-3" />
      </v-flex>
    </v-layout>
    <div style="margin-top: 20px" />
    <!-- :style="myStyle.layout" -->
    <v-layout
      align-center
      row
      wrap
      reverse="$vuetify.breakpoint.mdAndUp"
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <div
          class="font-weight-bold"
          :class="{'display-1 px-4': $vuetify.breakpoint.lgAndUp,'headline px-2': $vuetify.breakpoint.mdOnly, 'title px-0': $vuetify.breakpoint.smAndDown}"
        >
          <span style="font-family:Roboto">Built on Cutting-Edge Technology</span>
        </div>
        <div style="margin-top: 10px" />
        <div class="font-weight-light subheader-content"
             :class="{'headline px-4': $vuetify.breakpoint.lgAndUp,'subtitle-1 px-2': $vuetify.breakpoint.mdOnly, 'body-2 px-0': $vuetify.breakpoint.smAndDown}"
        >
          Powered by state-of-the-art technologies, <span class="edliy-e">&epsilon;</span>dliy provides learners with an effective, engaging and all-immersive learning experience.
          <br>
          <a v-scroll-to="'#subjectcard123'" class="btnLeft mt-3">Try It Yourself</a>
        </div>
      </v-flex>
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <v-img
          width="auto "
          class="mx-auto"
          max-height="500px"
          max-width="500px"
          src="assets/animation-4.svg"
          contain
        />
      </v-flex>
    </v-layout>
    <br>
  </div>
</template>
<script>
export default {
  name: 'AboutContent',
  props: {
    myStyle: {
      type: Object,
        default: () => {}
    },
  },
  data () {
    return {
      attrs: [
        {
          key: 'today',
          highlight: 'red',
          dates: new Date(),
        }],
    }
  },
}
</script>
