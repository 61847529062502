<template>
  <v-container class="main">
    <div id="daf">
      <v-layout
        align-center
        justify-center
        :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
      >
        <v-flex
          xs12
          sm12
          md12
          lg12
          xl12
        >
          <div
            class="font-weight-light"
            :class="{'headline px-4': $vuetify.breakpoint.lgAndUp,'subtitle-1 px-2': $vuetify.breakpoint.mdOnly, 'pa-0 body-2': $vuetify.breakpoint.smAndDown}"
          >
            <span class="edliy-e">&epsilon;</span>dliy is a visually interactive learning app. By combining the art of storytelling with the power of visual interactivity, <span class="edliy-e">&epsilon;</span>dliy makes learning math and science engaging and enjoyable for learners and educators alike.
            <br><br>
            Tap on an option to get started.
            <br>
            <br>
          </div>
        </v-flex>
      </v-layout>
      <v-layout
        justify-space-around
        align-center
        class="mb-3 pa-2"
      >
        <v-flex
          xl8
          sm8
          md8
          lg8
          xs12
        >
          <v-layout
            justify-space-around
            align-center
            class="mb-2 pa-1"
          >
            <v-flex
              xl4
              sm4
              md4
              lg4
              xs4
            >
              <v-layout justify-center align-center>
                <v-btn
                  fab
                  large
                  @click="$router.push({path:'/gradesMath'})"
                >
                  <v-img
                    src="/assets/launch.svg"
                    max-height="42px"
                    max-width="42px"
                    contain
                  />
                </v-btn>
              </v-layout>
              <v-layout class="mt-1" justify-center align-center>
                <h5 style="color:black">Start learning</h5>
              </v-layout>
            </v-flex>
            <v-flex
              xl4
              sm4
              md4
              lg4
              xs4
            >
              <v-layout justify-center align-center>
                <v-btn
                  fab
                  large
                  @click="$router.push({path:'/demo'})"
                >
                  <v-img
                    src="/assets/demoIcon.svg"
                    max-height="80px"
                    max-width="80px"
                    contain
                  />
                </v-btn>
              </v-layout>
              <v-layout class="mt-1" justify-center align-center>
                <h5 style="color:black">Watch a demo</h5>
              </v-layout>
            </v-flex>
            <v-flex
              xl4
              sm4
              md4
              lg4
              xs4
            >
              <v-layout justify-center align-center>
                <v-btn
                  fab
                  large
                  @click="$router.push({path:'/contact'})"
                >
                  <v-img
                    src="/assets/conversation.svg"
                    max-height="80px"
                    max-width="80px"
                    contain
                  />
                </v-btn>
              </v-layout>
              <v-layout class="mt-1" justify-center align-center>
                <h5 style="color:black">Ask an expert</h5>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout
        v-if="dialog"
        justify-center
        align-center
        style="position:fixed; top:0;bottom:0;left:0;right:0;z-index:100;"
      >
        <LoginDialog
          :status="dialog"
          :img="img1"
          @close="dialog=false"
        />
      </v-layout>
      <v-layout
        v-if="sdialog"
        justify-center
        align-center
        style="position:fixed; top:0;bottom:0;left:0;right:0;z-index:100;"
      >
        <LoginDialog
          :status="sdialog"
          :img="img2"
          @close="sdialog=false"
        />
      </v-layout>
      <v-layout
        align-center
        justify-center
        :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
      >
        <v-flex
          xs12
          sm12
          md12
          lg12
          xl12
        >
          <div
            class="font-weight-light subheader-content"
            :class="{'headline px-4': $vuetify.breakpoint.lgAndUp,'subtitle-1 px-2': $vuetify.breakpoint.mdOnly, 'body-2 px-0': $vuetify.breakpoint.smAndDown}"
          >
            Not ready yet? Keep scrolling to learn more about <span class="edliy-e">&epsilon;</span>dliy!
            <br>
            <br>
          </div>
        </v-flex>
      </v-layout>
    </div>
    <divide-content />
    <div style="margin-top: 5px" />
    <v-layout v-if="$vuetify.breakpoint.xlAndUp" justify-center>
      <v-card class="rounded-card" elevation="1" max-width="2400px">
        <edliy-content :my-style="style" />
      </v-card>
    </v-layout>
    <edliy-content v-else :my-style="style" />
    <div class="font-weight-medium"
         :class="{'display-1': $vuetify.breakpoint.mdAndUp, 'subheading': $vuetify.breakpoint.smAndDown}"
    >
      <a class="btntitle" href="#/courses" />
    </div>
    <divide-content />
    <v-layout v-if="$vuetify.breakpoint.xlAndUp" justify-center>
      <v-card class="rounded-card" elevation="1" max-width="2400px">
        <about-content :my-style="style" />
      </v-card>
    </v-layout>
    <about-content v-else :my-style="style" />
    <div class="font-weight-medium"
         :class="{'display-1': $vuetify.breakpoint.mdAndUp, 'subheading': $vuetify.breakpoint.smAndDown}"
    >
      <div class="font-weight-medium"
           :class="{'display-1': $vuetify.breakpoint.mdAndUp, 'subheading': $vuetify.breakpoint.smAndDown}"
      >
        <a class="btntitle" href="#/courses" />
      </div>
    </div>
    <!--  <v-divider v-if="$vuetify.breakpoint.xlAndDown" class="mt-3 mb-3" />-->
    <divide-content />
    <v-layout v-if="$vuetify.breakpoint.xlAndUp" justify-center>
      <v-card class="rounded-card" elevation="1" max-width="2400px">
        <topic-content :my-style="style" />
      </v-card>
    </v-layout>
    <topic-content v-else :my-style="style" />
    <div class="font-weight-medium"
         :class="{'display-1': $vuetify.breakpoint.xlAndUp, 'subheading': $vuetify.breakpoint.smAndDown}"
    >
      <div class="font-weight-medium"
           :class="{'display-1': $vuetify.breakpoint.mdAndUp, 'subheading': $vuetify.breakpoint.smAndDown}"
      >
        <a class="btntitle" href="#/courses" />
      </div>
    </div>
  </v-container>
</template>
<script>
import EdliyContent from './EdliyL.vue';
import AboutContent from './AboutContent.vue';
import TopicContent from './TopicContent.vue';
import DivideContent from './Divider.vue';
import LoginDialog from '../SocialLoginDialog.vue';
//import Boxes from './Boxes.js'
import { mapGetters } from 'vuex';
export default {
    name:'Home',
    components: {
    EdliyContent, AboutContent, TopicContent, DivideContent, LoginDialog
    },
    data () {
      return {
        style: {layout: "margin:5%"},
        dialog:false,
        sdialog:false,
        img1:'/assets/Learning.svg',
        img2:'/assets/Chemistry-6th.svg'
             }
            },
    created() {
      this.$store.commit('navigation/resetState');
    //  this.$store.commit('navigation/changeTitle', 'Visual + Interactive Learning');
              },
/*computed:{
      ...mapGetters(
        {
        showhome: 'navigation/showhome',
        showPhys: 'navigation/showPhys'
        })
      }, */
  /*    metaInfo: {
      title: "Edliy",
      meta: [
        { name: "description", content: "At Edliy, we are pioneering visual interactive technology to teach science and math concepts through visual storytelling." },
        { name: "keywords", content: "visual learning, interactive learning, concept building for students, science, engineering, mathematics"},
        { name: "robots", content: "index, follow"},
      ]
  }*/
  metaInfo: {
          title: "Edliy",
          titleTemplate:"%s | Learn interactively",
          meta: [ { vmid:"description",
                    name:"description",
                    content: "At Edliy, we are pioneering visually interactive technology to teach science and math concepts through visual storytelling."
                  },
                  { vmid:"keywords",
                    name:"keywords",
                    content: "visual learning, interactive learning, concept building for students, science, engineering, mathematics"
                  },
                  { vmid:"chars", charset:"utf-8"},
                  { vmid:"viewport", name:"viewport", content:"width=device-width, initial-scale=1"},
                ],
            link: [{rel:"canonical", href:"https://edliy.com"}]
        },
  }
</script>
<style lang="scss">
@import 'src/styles/screen-sizes.scss';
#jxgbox4a input {border-style:solid;border-radius:4px;background-color:#FEBD49}
.about-img {
    margin-left: 100px;
}
.reload{
position: relative;
top: -50px;
left: 30px;
}
.main{
max-width:1800px;
width:100%;
}
.subhead {
color: #903;
float: left;
font-family: Georgia;
font-size: 75px;
line-height: 60px;
padding-top: 4px;
padding-right: 8px;
}
.btnhead{
background-color: #4CAF50; /* Green */
border-style: solid;
border: 0px;
border-radius: 3.5px;
color: white;
padding: 5px 15px 5px 15px;
text-align: center;
text-decoration: none;
display: inline-block;
font-family: Oswald;
@include respond-to(less-smallest-screens) {font-size: 12px;}
@include respond-to(smallest-screens) {font-size: 14px;}
@include respond-to(small-screens) {font-size: 16px;}
@include respond-to(medium-screens) {font-size: 20px;}
@include respond-to(large-screens) {font-size: 25px;}
@include respond-to(largest-screens) {font-size: 30px;}
}
.btntitle{
border-style: solid;
border: 0px;
border-radius: 3.5px;
color: black;
padding: 5px 15px 5px 15px;
text-align: center;
text-decoration: none;
display: inline-block;
font-family: Oswald;
font-size :32px;
}
@include respond-to(less-smallest-screens) {
.size{
font-size:28px;
//font-weight: bold;
font-family:Oswald;
}
}
@include respond-to(smallest-screens) {
.size{
font-size:29px;
//font-weight: bold;
font-family:Oswald;
position:relative;
}
}
@include respond-to(small-screens) {
.size{
font-size:40px;
//font-weight: bold;
font-family:Oswald;
position:relative;
}
}
@include respond-to(medium-screens) {
.size{
font-size:62px;
font-weight:light;
font-family:Oswald;
position:relative;
}
}
@include respond-to(large-screens) {
.size{
font-size:56px;
font-weight: light;
font-family:Oswald;
position:relative;
}
}
@include respond-to(largest-screens) {
.size{
font-size:62px;
font-weight: light;
font-family:Oswald;
position:relative;
}
}
.imgC{
margin-left:auto;
margin-right:auto;
margin-top: auto;
margin-bottom: auto;
@include respond-to(less-smallest-screens) {width: 100%;}
@include respond-to(smallest-screens) {width: 80%;}
@include respond-to(small-screens) {width: 75%;}
@include respond-to(medium-screens) {width: 50%;}
@include respond-to(large-screens) {width: 90%;}
@include respond-to(largest-screens) {width: 90%;}
}
.jsxbox {
@include respond-to(less-smallest-screens) {width:12vw; height:12vw;}
@include respond-to(smallest-screens) {width:12vw; height:12vw;}
@include respond-to(small-screens) {width:12vw; height:12vw;}
@include respond-to(medium-screens) {width:24vw; height:24vw;}
@include respond-to(large-screens) {width:24vw; height:24vw;}
@include respond-to(largest-screens) {width:24vw; height:24vw;}
}
.rounded-card{
    border-radius:10px;
    width:100%;
}
</style>
