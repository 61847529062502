<template>
  <div id="subjectcard123">
    <v-layout
      align-center
      justify-center
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm12
        md12
        lg12
        xl12
      >
        <div
          :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
        >
          <v-layout class="mt-4">
            <h1 style="text-align:start">
              Explore <span class="header-etsy">&epsilon;</span>dliy!
            </h1>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>
    <div style="margin-top: 10px" />
    <v-layout
      align-center
      justify-center
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <div
        class="font-weight-light subheader-content"
        :class="{'headline px-4': $vuetify.breakpoint.lgAndUp,'subtitle-1 px-2': $vuetify.breakpoint.mdOnly, 'body-1 px-0': $vuetify.breakpoint.smAndDown}"
      >
        Explore our library of more than 100 meticulously authored lessons on a variety of
        topics in Science, Engineering and Mathematics.
        Each lesson contains brilliantly designed visually interactive illustrations that provide students with an engaging and fun-filled learning experience.
        <br>
        <br>
      </div>
    </v-layout>
    <div style="margin-top: 10px" />
    <!--  <v-layout
        align-center
        justify-center
        row
      >
        <v-flex
          v-for="topic in topics"
          :key="topic.key"
          xs12
          sm12
          md12
          lg12
          xl12
        >
          <subtopic-card
            :headline="topic.headline"
            :description="topic.description"
            :img="topic.img"
            :path="topic.path"
          />
        </v-flex>
      </v-layout> -->
    <v-layout
      align-center
      justify-center
      row
      :class="{'headline mt-3 px-4': $vuetify.breakpoint.lgAndUp,'subtitle-1 mt-3 px-2': $vuetify.breakpoint.mdOnly, 'body-2 mt-3 px-1': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        v-for="topic in topics"
        :key="topic.key"
        xs12
        sm12
        md12
        lg12
        xl12
      >
        <!--<subtopic-card
              :headline="cat.title"
               description=""
              :img="cat.img"
              :path="cat.link"
            /> -->
        <subtopic-card
          :headline="topic.headline"
          :description="topic.description"
          :img="topic.img"
          :path="topic.path"
        />
      </v-flex>
    </v-layout>
    <br>
    <br>
  </div>
</template>
<script>
import axios from 'axios';
import SubtopicCard from '../SubTopics.vue';
export default {
    name: 'TopicContent',
    components: {
    SubtopicCard
    },
    data () {
      return {
        style: {layout: "margin: 20px"},
        topics: [
          {
            headline: "Foundations",
            description: "(Grades 6-8)",
            img: "/assets/basics.svg",
            path: "/gradesFoundations"
          //  path: "/gradesFoundations?id=RGNYTWJhSmFsVkVOOTV0QXNOVlYxUT09"
          },
          {
            headline: "Intermediate",
            description: "(Grades 8-10)",
            img: "/assets/intermediate.svg",
            path: "/gradesIntermediate"
            //path: "/gradesIntermediate?id=MHVvcThONUMwsS1hNekhkOWlGdXR4dz09"
          },
          {
            headline: "Advanced",
            description: "(Grades 10-12)",
            img: "/assets/advanced.svg",
            path: "/gradesIntermediate"
          //  path: "/gradesAdvanced?id=Z1lWTmJ0OXJBWkNZR1orT1MwMWlsUT09"
          },
        ]
        }
      },
 async mounted () {
          console.log("userId::", localStorage.getItem('userId'))
          const getCat = await axios.get(process.env.VUE_APP_API+'categories');
          if(getCat.data.success){
            this.catArr = getCat.data.data.categories.slice(0, 3);;
            console.log("cats::::", getCat.data.data.categories)
            for(let i in this.catArr){
              this.catArr[i].link = this.catArr[i].link+'?id='+this.catArr[i].id
              if(this.catArr[i].title== "Physics"){
                this.catArr[i].img = '/assets/Phys.svg';
                // this.catArr[i].link = '/gradesPhy?id='+this.catArr[i].id;
              }
              if(this.catArr[i].title== "Chemistry"){
                this.catArr[i].img = '/assets/Chem.svg';
                // this.catArr[i].link = '/gradesChem?id='+this.catArr[i].id;
              }
              if(this.catArr[i].title== "Mathematics"){
                this.catArr[i].img = '/assets/classroom.svg';
                // this.catArr[i].link = '/gradesMath?id='+this.catArr[i].id;
              }
            }
          }
      if(window.location.href.includes('#enrolled'))
      {
      document.getElementById('enrolled').scrollIntoView()
      }
    }
}
</script>
